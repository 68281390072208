import { reactive } from 'vue'

export const store = reactive({
  /** @type {String} */ 
  deckFilter: "",

  /** @type {Boolean} */ 
  imagesVisible: true,

  setDeckFilter(deck) {
    store.deckFilter = deck;
  }
})

export function toggleImagesVisible() {
  console.log("toggling images")
  store.imagesVisible = !store.imagesVisible;
  console.log(store.imagesVisible)
}