<template>
  <div class="deckFilter">
    <div class="deckFilterHeader">
      <span @click="this.toggleShowDeckFilter()">
        <span v-if="showDeckFilter" style="font-weight: bold">
          &#8593;
        </span>
        <span v-else style="font-weight: bold">
          &#8595;
        </span>

        <span 
          class="filterTitle" 
          >
          Deck Filter:
        </span>
      </span>

      <span v-if="deckFilter" class="selectedDeckFilter">
        {{ this.deckFilter }}
      </span>

      <span v-if="deckFilter" class="resetButton">
        <button @click="this.reset">
          <i>Reset Deck Filter</i>
        </button>
      </span>      
    </div>
    
    <div v-if="showDeckFilter" class="deckFilterButtonContainer">
      <button 
        v-for="deck in decks" 
        :key="deck" 
        class="deckFilterButton"
        @click="this.updateDeckFilter(deck)"
      >{{ deck }}</button>
    </div>
  </div>
</template>

<script>
import proxies from '../assets/proxies.json'
import { store } from '@/store'

  export default {
    name: 'DeskFilter',
    data() {
      return {
        decks: [],
        deckFilter: "",
        showDeckFilter: false,
      }
    },
    mounted() {
      this.deckFilter = store.deckFilter;

      this.parseDecks()
    },
    computed: {
      showArtistsLabel() {
        return this.showArtists ? "Switch back to Proxies" : "Show Owned Cards with Artists at MagicCon 24"
      }
    },
    methods: {
      reset() {
        console.log("Resetting deck filter")
        this.deckFilter = null
        store.deckFilter = this.deckFilter;
        this.turnOffDeckFilter()
      },
      parseDecks() {
        for (const card of proxies) {
          if (this.decks.indexOf(card.Deck) === -1) {
            this.decks.push(card.Deck);
            console.log("Added deck: ", card.Deck)
          }
        }

        this.decks.sort()
      },
      toggleShowDeckFilter() {
        this.showDeckFilter = !this.showDeckFilter
        console.log("Set ShowDeckFilter to ", this.showDeckFilter)
      },
      turnOffDeckFilter() {
        console.log("Setting deckFilter to false")
        this.showDeckFilter = false
      },
      updateDeckFilter(deck) {
        console.log("Setting filter to ", deck)
        this.deckFilter = deck
        store.deckFilter = this.deckFilter
        this.turnOffDeckFilter()
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.cardName {
  font-weight: bold
}

.cards {
  font-size:2em;
}

.deckFilter {
  background-color: #813772;
  border-width: 1px;
  border-style: dotted;

  color: white;

  padding: 10px;
  margin: 5%;
}

.deckFilterButton {
  margin: 2px 5px;
}

.deckFilterButtonContainer {
  margin: 10px;
  max-width: 100%;

  position: absolute;
  width: 20%;
  background-color: #813772;
}

.filterTitle {
  font-weight: bold;
}

.resetButton {
  margin-left: 20px;
}

.deckFilterTitle {
  padding-left: 20px;
  font-weight: bold;
}

@media only screen and (max-width: 500px) {
  .cards {
    font-size:.85em;
  }

  .deckFilter {
    border-width: 1px;
    border-style: dotted;

    color: white;

    padding: 10px;
    margin-left: 5%;
    font-size: .8em;
  }

  .deckFilterButton {
    margin: 2px 5px;
  }

  .deckFilterButtonContainer {
    margin: 10px;
    max-width: 100%;

    position: absolute;
    width: 20%;
    background-color: #813772;
  }

  .resetButton {
    margin-left: 0px;
  }

  .selectedDeckFilter {
    font-style: italic;
    font-size: .6em;
    padding: 0px;
    margin: 0px;
  }

  h3 {
    font-size: 14px;
  }

  button {
    font-size: 10px;
  }
}
</style>
