<template>
  <div class="imageToggleContainer" @click="toggleImagesVisible">
    <span v-if="imagesVisible">
      Switch to Deck List Mode
    </span>
    <span v-else>
      Switch to Image Mode
    </span>
  </div>
</template>

<script setup>
import { store } from '@/store'
import { toggleImagesVisible } from '@/store';
import { computed } from 'vue'

const imagesVisible = computed({
  get() { return store.imagesVisible}
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.imageToggleContainer {
  background-color: #B82601;
  border-width: 1px;
  border-style: dotted;

  color: white;

  padding: 10px;
  margin-right: 5%;
}


@media only screen and (max-width: 500px) {
  .imageToggleContainer {
    font-size: .8em;
  }
}
</style>
