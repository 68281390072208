<template>
  <HeaderView />

  <CardList v-if="!imagesVisible"/>
  <CardView v-if="imagesVisible"/>
</template>

<script setup>
import CardList from '@/components/CardList.vue';
import CardView from '@/components/CardView.vue';
import HeaderView from '@/components/HeaderView.vue';
import { store } from '@/store'
import { computed } from 'vue'

const imagesVisible = computed({
  get() { return store.imagesVisible}
})

</script>

<style>

</style>
