import { createRouter, createWebHistory } from 'vue-router'
// import HomeView from '@/views/HomeView.vue'
import MTGProxyTracker from '@/views/MTGProxyTracker.vue'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'MTGProxyTracker',
      component: MTGProxyTracker,
    },
    // {
    //   path: '/MTGProxyTracker',
    //   name: 'MTGProxyTracker',
    //   component: MTGProxyTracker,
    // },
  ],
})

export default router
