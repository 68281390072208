<template>
  <div class="sorter">
    Sorting Options: 
    
    <span v-if="this.nameSorting">
      <button 
        @click="sortCardsByPrice" 
        class="sortButton"
        >
        Sort by Card Price
      </button>
    </span>

    <span v-else>
      <button 
        @click="sortCardsByName" 
        class="sortButton"
        >
        Sort by Card Name
      </button>
    </span>
  </div>

  <div v-for="card in cards" :key="card.CardName+card.Deck" class="cards">
    <span class="cardName"><a :href="card.ScryfallURL" target="_blank">{{ card.CardName }}</a></span> ({{ card.Price }} / {{ card.Foil }}) <span v-if="!deckFilter">for <b>{{ card.Deck }}</b></span>
  </div>
</template>

<script>
import proxies from '../assets/proxies.json'
import { store } from '@/store'

  export default {
    name: 'CardList',
    data() {
      return {
        cards: proxies,
        showArtists: false,
        nameSorting: true
      }
    },
    mounted() {
      this.filterCards()
    },
    computed: {
      deckFilter() {
        return store.deckFilter
      },
      showArtistsLabel() {
        return this.showArtists ? "Switch back to Proxies" : "Show Owned Cards with Artists at MagicCon 24"
      },
    },
    methods: {
      changeSorting() {
        if (this.nameSorting) {
          this.sortCardsByPrice
        } else {
          this.sortCardsByName
        }
      },
      filterCards() {
        // console.log("filtering cards")
        this.cards = []

        if (this.deckFilter) {
          for (const proxy in proxies) {
            let currentCard = proxies[proxy]

            if (currentCard.Deck == store.deckFilter) {
              this.cards.push(currentCard)
            }
          }
        } else {
          this.cards = proxies
        }
      },
      sortCardsByName() {
        console.log("sorting by name")
        this.cards.sort((a, b) => a.CardName > b.CardName);
        this.nameSorting = true
      },
      sortCardsByPrice() {
        console.log("sorting")
        this.cards.sort((a, b) => a.Price - b.Price);
        this.nameSorting = false
      }
    },
    watch: {
      deckFilter() {
        this.filterCards()
      }
    }
  }
</script>

<style scoped>
.cardName {
  font-weight: bold
}

.sorter {
  color: white;
  margin: 10px 0px;
  background-color: #B82601;
  padding: 10px;
}

.sortButton {
  margin: 0px 5px;
}

@media only screen and (max-width: 500px) {
  .cards {
    font-size:.85em;
  }
}
</style>
